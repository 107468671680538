.App {
  display: flex;
  flex-direction: column;

  justify-content: center;
  background-color: #fff;
  text-align: center;

  margin: auto;
  .wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;

  }
}

a {
  text-decoration: none;
  &:link, &:visited {
    text-decoration: none;
    padding: 0 10px;
    color: #333;
  }

  &:hover {
    text-decoration: underline;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

img.fadeIn {
  animation-name: fadeIn;
  animation-duration: 10s;
  animation-fill-mode: forwards;
}
